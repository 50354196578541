import Arrow from "../components/icons/Arrow";

export default function calculateTransactionTypeWithIcon(PaidIn_PaidOut, isHold) {
  // Combine PaidIn_PaidOut and isHold into a string for the switch statement
  const key = `${PaidIn_PaidOut}_${isHold}`;

  // Transaction type and icon determination
  let transactionType, transactionIcon;

  switch (key) {
    case "1_false":
      transactionType = "Paid In";
      transactionIcon = "/icons/downarrow.svg";
      break;
    case "1_true":
      transactionType = "Pending Paid In";
      transactionIcon = "/icons/upArrow.png";
      break;
    case "-1_false":
      transactionType = "Paid Out";
      transactionIcon = "/icons/arrowup.svg";
      break;
    case "-1_true":
      transactionType = "Pending Paid Out";
      transactionIcon = "/icons/upArrow.png";
      break;
    case "2_false":
      transactionType = "Paid Out";
      transactionIcon = "/icons/arrowup.svg";
      break;
    case "2_true":
      transactionType = "Pending Credit";
      transactionIcon = "/icons/downarrow.svg";
      break;
    case "-2_true":
      transactionType = "Pending Debit";
      transactionIcon = "/icons/upArrow.png";
      break;
    case "-2_false":
      transactionType = "Debit";
      transactionIcon = "/icons/arrowup.svg";
      break;
    case "-3_false":
      transactionType = "Fees";
      transactionIcon = "/icons/downarrow.svg";
      break;
    case "4_false":
      transactionType = "Int. Received";
      transactionIcon = "/icons/downarrow.svg";
      break;
    case "4_true":
      transactionType = "Pending Int. Received";
      transactionIcon = "/icons/downarrow.svg";
      break;
    case "-4_true":
      transactionType = "Pending Int. Send";
      transactionIcon = "/icons/upArrow.png";
      break;
    case "-4_false":
      transactionType = "Int. Send";
      transactionIcon = "/icons/arrowup.svg";
      break;
    default:
      transactionType = "";
      transactionIcon = "/icons/more.png";
  }

  // Return an object with both transaction type and icon
  return { transactionType, transactionIcon };
}

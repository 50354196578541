import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAcceptMemberMutation } from "../store";
import Loader from "../components/shared/Loader";

const ActivateRecipient = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [acceptMember, { isLoading, isError, data }] = useAcceptMemberMutation();
  const [message, setMessage] = useState("Activating recipient...");

  useEffect(() => {
    if (token) {
      acceptMember({ token })
        .unwrap()
        .then((response) => setMessage(response.message))
        .catch(() => setMessage("Invalid or expired activation link."));
    } else {
      setMessage("No activation token found.");
    }
  }, [token, acceptMember]);
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h1 className="text-5xl font-bold">{message}</h1>
    </div>
  );
};

export default ActivateRecipient;

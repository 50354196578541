import React from "react";
import "./css/recipient.css";

const RecipientModal = ({ handleModalClose, setIsOpen, handleSubmit, handleChange, handleBlur, values }) => {
  return (
    <div className="modal-overlay" onClick={handleModalClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-title">Recipient Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <input
              type="text"
              id="userID"
              name="userID"
              value={values.userID}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Please enter the ACT ID"
              className={`w-full px-4 py-3 border rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977] `}
            />
          </div>
          <div className="mb-2">
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Full Name, optional"
              className={`w-full px-4 py-3 border rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977] `}
            />
          </div>

          <div className="modal-footer">
            <button className="btn cancel" onClick={handleModalClose}>
              Cancel
            </button>
            <button className="btn submit" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecipientModal;

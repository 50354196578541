import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFetchClientsBalanceQuery } from "../../../store";
import Loader from "../../shared/Loader";
import "./css/clientsDetails.css";

export default function ClientsDetails() {
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);

  const payload = useMemo(() => ({ clientID: client?.ID, token }), [client?.ID, token]);
  const { data: balance, isError, error, isLoading } = useFetchClientsBalanceQuery(payload);

  // if (isLoading) {
  //   return <Loader />;
  // }

  if (isError) {
    return <div className="error-message">Error: {error.message}</div>;
  }

  return (
    <section>
      <div className="mb-6">
        <h2 className="text-5xl font-bold text-white mb-1">Welcome Back</h2>
        <p className="text-white text-lg">Hi {client?.FullName}!</p>
      </div>
      <div className="flex gap-5 mb-5">
        <div className="w-[302px] h-[124px] bg-white text-xl text-[#252525] flex flex-col justify-center items-center rounded">
          <p className="mb-1">ACT Account ID</p>
          <p>{client?.ID}</p>
        </div>
        <div className="w-[302px] h-[124px] bg-white text-xl text-[#252525] flex flex-col justify-center items-center rounded">
          <p className="mb-1">Your Account Balance</p>
          <div className="flex gap-1">
            <img src="/icons/sidebar/Pound.png" alt="pound" />
            <p>{balance}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

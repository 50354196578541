import React, { useMemo, useState, useEffect } from "react";
import {
  useFetchCoilelClientsQuery,
  useFetchDistributionHistoryQuery,
  useRoshCoilelTopupMutation,
} from "../store";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChecklistIcon from "@mui/icons-material/Checklist";
import DvrIcon from "@mui/icons-material/Dvr";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./css/Distribution.css";
import CustomTableNew from "../components/shared/customTable/CustomTableNew";
import useToastSpinner from "../hooks/useToastSpinner";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Distribution = () => {
  const [tableData, setTableData] = useState([]);
  const [globalNote, setGlobalNote] = useState("");
  const [value, setValue] = useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const { ID, MobileAbroad } = client || {};
  const { toastSpinner } = useToastSpinner();
  const payload = useMemo(
    () => ({ clientID: ID, coilelID: MobileAbroad, token }),
    [ID, MobileAbroad, token]
  );

  const [requestTopup, { isLoading: topupLoading }] =
    useRoshCoilelTopupMutation();

  // Call both APIs unconditionally
  const { data: allClientData = [], isLoading: CLoading } =
    useFetchCoilelClientsQuery(payload);
  const { data: allDistributedClient = [], isLoading: isLoadingHistory } =
    useFetchDistributionHistoryQuery({
      coilelID: MobileAbroad,
      clientID: ID,
      token,
    });
  // Process data for distributionHistory table
  const uniqueTypes = useMemo(() => {
    return [
      ...new Set(
        (allDistributedClient?.data || []).map(
          (row) => `${row.PaidIn_PaidOut}:${row.OnHold}`
        )
      ),
    ];
  }, [allDistributedClient?.data]);

  const types = useMemo(
    () => ({
      1: "Paid In",
      "-1": "Paid Out",
      2: "Credit",
      "-2": "Debit",
      "-3": "Fees",
      4: "Int. Received",
      "-4": "Int. Send",
    }),
    []
  );

  const transactionTypes = useMemo(() => {
    return uniqueTypes.map((type) => {
      const [paidInPaidOut, onHold] = type.split(":");
      return onHold === "true"
        ? `Pending ${types[paidInPaidOut]}`
        : types[paidInPaidOut];
    });
  }, [uniqueTypes, types]);

  // Initialize tableData with modifiedData whenever allClientData changes
  useEffect(() => {
    if (allClientData?.data) {
      const modifiedData = allClientData.data.map((client) => ({
        ...client,
        paymentMethod: "",
        amount: "",
      }));
      setTableData(modifiedData);
    }
  }, [allClientData]);

  // Generate modified data for the distribution history
  const modifiedDistributedClientData = useMemo(() => {
    return (
      allDistributedClient?.data?.map((row, index) => ({
        ...row,
        transactionType: transactionTypes[index] || "",
        moneyType: row.MoneyType || "",
        formattedDate: new Date(row.DDate).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      })) || []
    );
  }, [allDistributedClient?.data, transactionTypes]);

  // Define the columns for the Recipients table
  const columnsForRecipents = [
    { label: "ID", key: "ID" },
    { label: "Name", key: "FullName" },
    { label: "Default Payment Method", key: "DefaultPayment" },
    { label: "P.P Card Status", key: "CardStatus" },
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Amount", key: "amount" },
    // { label: 'Submit', key: 'submit' },
  ];

  // Define the columns for the distributionHistory table
  const columnsForDistributionHistory = [
    { label: "Date", key: "formattedDate" },
    { label: "Name", key: "FullName" },
    { label: "Transaction Type", key: "transactionType" },
    { label: "Amount", key: "Amount" },
    { label: "Note", key: "Note" },
  ];

  // Generate topUpOptions based on client CardStatus
  const getTopUpOptions = (cardStatus) => {
    if (cardStatus === "Active" || cardStatus === "Card Ordered") {
      return (
        <>
          <option value="Bank">Bank Pay out</option>
          <option value="P.P. Card">Prepaid Card Top-Up</option>
        </>
      );
    }
    return <option value="Bank">Bank Pay out</option>;
  };

  // Handle input change for the amount and paymentMethod columns
  const handleInputChange = (e, rowId, key) => {
    const { value } = e.target;
    setTableData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.ID === rowId ? { ...row, [key]: value } : row
      );
      return updatedData;
    });
  };

  // Function to handle key press (Enter) and trigger API call
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  // Function to prepare the structured data and make the API call
  const handleSubmit = async () => {
    const coilel = tableData
      .map((client) => ({
        clientID: Number(client.ID),
        amount: Number(client.amount) || 0,
        PaymentMethod: client.paymentMethod || "Bank",
      }))
      .filter((client) => client.amount !== 0); // Filter out entries with amount = 0

    const payload = {
      clientID: Number(ID),
      note: globalNote,
      coilel,
      token,
    };

    try {
      await toastSpinner(
        requestTopup(payload),
        "Processing top-up requests...",
        "Top-up requests processed successfully.",
        "There was an error processing the top-up requests."
      );

      // Reset the amount and paymentMethod fields
      const resetData = tableData.map((client) => ({
        ...client,
        paymentMethod: "",
        amount: "",
      }));

      setTableData(resetData);
      setGlobalNote("");
    } catch (err) {
      console.error("Unexpected error during top-up request:", err);
      toast.error("There is a server-side error!");
    }
  };

  return (
    <section className="distributionContainer">
      <Box
        sx={{ bgcolor: "background.paper", width: "100%", marginTop: "30px" }}
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            // variant="fullWidth"
            // aria-label="full width tabs example"
          >
            <Tab
              label="Distribution"
              icon={<ChecklistIcon />}
              {...a11yProps(0)}
            />
            <Tab
              label="Distribution History"
              icon={<DvrIcon />}
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CustomTableNew
            columns={columnsForRecipents}
            data={tableData}
            getTopUpOptions={getTopUpOptions}
            handleInputChange={handleInputChange}
            handleKeyPress={handleKeyPress}
            handleSubmit={handleSubmit}
            globalNote={globalNote}
            setGlobalNote={setGlobalNote}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <CustomTableNew
            value={value}
            columns={columnsForDistributionHistory}
            data={modifiedDistributedClientData}
          />
        </TabPanel>
      </Box>
    </section>
  );
};

export default Distribution;

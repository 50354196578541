import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./layout.css";
import { persistor, setSelectedClient, successLogout } from "../../store";
import { useDispatch, useSelector } from "react-redux";

const AccountLayout = () => {
  const isAuth = useSelector((state) => state.user.isAuthenticated);
  const clients = useSelector((state) => state.user.clients);
  const currClient = useSelector((state) => state.user.selectedClient);
  const coilelID = useSelector((state) => state.user.coilelID);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle Sidebar visibility
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const sidebarItems = [
    { label: "Dashboard", to: "/account", icon: "/icons/sidebar/Laptop.png" },
    {
      label: "Request a payout",
      to: "/account/request-topup",
      icon: "/icons/sidebar/Pound.png",
    },
    {
      label: "Donate to a charity",
      to: "/account/internal-charity",
      icon: "/icons/sidebar/Heart.png",
    },
    {
      label: "Internal transfer fund",
      to: "/account/internal-individual",
      icon: "/icons/sidebar/Arrow.png",
    },
    ...(coilelID
      ? [
          {
            label: "My recipients",
            to: "/account/my-recipients",
            icon: "/icons/sidebar/recipient.png",
          },
        ]
      : []),
    ...(coilelID
      ? [
          {
            label: "Distribution",
            to: "/account/distribution",
            icon: "/icons/sidebar/distribution.png",
          },
        ]
      : []),
    {
      label: "My Documents",
      to: "/account/my-documents",
      icon: "/icons/sidebar/Documents.png",
    },
    {
      label: "Recurring",
      to: "/account/recurring-donations",
      icon: "/icons/sidebar/Appointment.png",
    },
    {
      label: "Send us A Message",
      to: "/account/send-request",
      icon: "/icons/sidebar/Comment.png",
    },
    {
      label: "My Account Details",
      to: "/account/account-details",
      icon: "/icons/sidebar/Account.png",
    },
  ];

  const handleLogout = async () => {
    // googleLogout();
    persistor.purge();
    dispatch(successLogout());
    navigate("/");
  };

  const handelSelect = (event) => {
    const idx = event.target.value;
    dispatch(setSelectedClient(idx));
  };

  let clientSelector = "";

  if (isAuth && clients.length === 1) {
    clientSelector = (
      <div className="w-full bg-primary py-6 text-lightB text-center">
        {`${clients[currClient]?.ID} - ${clients[currClient]?.FullName}`}
      </div>
    );
  } else if (isAuth && clients.length > 1) {
    clientSelector = (
      <div className="mt-4 text-lg">
        <select
          id="select-client"
          className="w-full bg-primary py-6 text-lightB text-center cursor-pointer"
          value={currClient}
          onChange={handelSelect}
        >
          {clients.map((client, idx) => (
            <option key={`client${idx}`} value={idx} className="text-lightB">
              {`${client.ID} - ${client.FullName}`}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 z-40 flex flex-col h-full w-64 lg:w-80 xl:w-[420px] bg-white border-r border-gray-200 transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform md:translate-x-0`}
      >
        <div className="p-4">
          <Link to="/">
            <img src="/logo.png" alt="Logo" className="w-32" />
          </Link>
        </div>
        <nav className="flex-grow mt-4 overflow-y-auto">
          {clientSelector}
          <ul>
            {sidebarItems.map((item) => (
              <li key={item.to}>
                <Link
                  to={item.to}
                  className={`flex gap-4 items-center px-5 py-4 text-lg ${
                    location.pathname === item.to
                      ? "bg-lightB text-primary font-bold"
                      : "text-[#888888] font-normal"
                  }`}
                >
                  <img src={item.icon} alt="icon" />
                  <span
                    className={`text-lg ${
                      location.pathname === item.to
                        ? "text-primary font-bold"
                        : "text-[#888888] font-normal"
                    }`}
                  >
                    {item.label}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <Link
          onClick={handleLogout}
          className="flex text-lg w-full gap-4 items-center px-5 py-4 text-[#888888] mt-auto"
        >
          <img src="/icons/sidebar/Logout.png" alt="icon" />
          <span>Logout</span>
        </Link>
      </aside>

      {/* Overlay for Sidebar on Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Main Content */}
      <div className="flex-1 md:ml-64 lg:ml-80 xl:ml-[420px] px-6 py-10 bg-primary">
        {/* Header with Hamburger Menu */}
        <header className="flex items-center md:hidden justify-between mb-6">
          <button
            onClick={toggleSidebar}
            className="md:hidden text-2xl text-blue-800"
          >
            {isSidebarOpen ? <p>Open</p> : <p>Close</p>}
          </button>
        </header>

        <Outlet />
      </div>
    </div>
  );
};

export default AccountLayout;

import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  googleClientID,
  successLogin,
  useForgotPasswordMutation,
  useLoginByPasswordMutation,
  useLoginByPasswordWithOtpMutation,
  useVerifyOtpMutation,
} from "../store";

import { loginSchema, otpEmailValidationSchema } from "../Validations/LoginValidations";
import useToastSpinner from "../hooks/useToastSpinner";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginBtn from "../components/button/GoogleLoginBtn";

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpMode, setIsOtpMode] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes
  const [otp, setOtp] = useState("");
  const [eamil, setEmail] = useState("");
  const [step, setStep] = useState("initial"); // 'initial', 'verificationEmail'
  const [password, setPassword] = useState("");
  const [verificationEmail, setVerificationEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isOtpLogin, setIsOtpLogin] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toastSpinner } = useToastSpinner();
  const [LoginByPassword] = useLoginByPasswordMutation();
  const [loginByPasswordWithOtp] = useLoginByPasswordWithOtpMutation();
  const [verifyOtp] = useVerifyOtpMutation();

  // Timer countdown for OTP
  useEffect(() => {
    if (otpSent && timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [otpSent, timeLeft]);

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        const res = await toastSpinner(
          LoginByPassword(values),
          "Logging in...",
          "You have logged in successfully.",
          "Failed to login."
        );

        if (res?.error) {
          // Handle additional error details if needed
          toast.error("Failed to login: " + res.error.data.message);
        } else {
          // Dispatch successful login details
          dispatch(
            successLogin({
              user: res?.data?.data.user,
              token: res?.data?.data.token.token,
              expiresIn: res?.data?.data.token.expiresIn,
            })
          );

          // Navigate to the desired page
          navigate("/account");
        }
      } catch (error) {
        console.error("Error during login:", error);

        // Optionally handle any unexpected errors
        toast.error("An unexpected error occurred. Please try again.");
      } finally {
        // Ensure the form is reset regardless of success or failure
        actions.resetForm();
      }
    },
    [LoginByPassword, dispatch, navigate, toastSpinner]
  );

  const handleSendCode = useCallback(
    async (values, actions) => {
      try {
        setEmail(values.email);
        const res = await toastSpinner(loginByPasswordWithOtp({ email: verificationEmail }), "Sending OTP...");

        if (res?.error) {
          toast.error("Login failed: " + res.error.data.message);
        } else {
          setIsModalOpen(true);
          setIsOtpMode(true);
          setOtpSent(true);
          setTimeLeft(300); // Reset timer
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again.");
      }
    },
    [LoginByPassword, toastSpinner]
  );

  const formik = useFormik({
    initialValues: {
      verificationEmail: "",
    },
    validationSchema: otpEmailValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setVerificationEmail(values.verificationEmail);
        const res = await toastSpinner(loginByPasswordWithOtp({ email: values.verificationEmail }), "Sending OTP...");

        if (res?.error) {
          toast.error("Login failed: " + res.error.data.message);
        } else {
          setIsModalOpen(true);
          setIsOtpMode(true);
          setOtpSent(true);
          setTimeLeft(300); // Reset timer\
          resetForm();
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again.");
      }
    },
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit,
  });

  const isAuth = useSelector((state) => state.user.isAuthenticated);
  useEffect(() => {
    if (isAuth) {
      navigate("/account");
    }
  }, [isAuth, navigate]);

  const handleEmailVerification = () => {
    setStep("verificationEmail");
  };

  const handleVerify = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setEmail(values.email);

        const res = await toastSpinner(
          verifyOtp({
            email: verificationEmail,
            otp: verificationCode,
          }),
          "Verifing OTP...",
          "You have logged in successfully.",
          "Failed to send OTP."
        );

        if (res?.error) {
          toast.error("Login failed: " + res.error.data.message);
        } else {
          // Dispatch successful login details
          dispatch(
            successLogin({
              user: res?.data?.data.user,
              token: res?.data?.data.token.token,
              expiresIn: res?.data?.data.token.expiresIn,
            })
          );

          setIsModalOpen(true);
          setIsOtpMode(true);
          setOtpSent(true);
          setTimeLeft(300); // Reset timer
          setIsModalOpen(false);
          setStep("initial");
          // Navigate to the desired page
          navigate("/account");
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again.");
      }
    },
    [LoginByPassword, toastSpinner]
  );
  return (
    <GoogleOAuthProvider clientId={googleClientID}>
      <div
        onSubmit={handleSubmit}
        className="flex items-center justify-center min-h-screen mt-10"
        style={{
          backgroundImage: "url('/bglogo.png')",
          // backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <div className="flex items-center justify-center min-h-screen bg-gray-100"> */}
        <div className="w-full max-w-xl px-8 py-10 rounded-lg relative text-center">
          {step === "initial" && (
            <div className="space-y-6">
              <h1 className="text-5xl font-bold text-primary mb-5">Hi there!</h1>

              <p className="text-[#1B1B1B] text-2xl mb-9">
                Welcome to <span className="font-bold text-primary">ACT</span>
              </p>
              {/* Google Login Button */}
              <GoogleLoginBtn />
              <div className="my-9 flex justify-center items-center gap-2">
                <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
                <p className="text-[#1B1B1B] text-lg">or</p>
                <div className="w-16 bg-[#B1B1B1] h-[1px]"></div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-2">
                  <input
                    type="email"
                    id="verificationEmail"
                    name="verificationEmail"
                    value={formik.values.verificationEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email"
                    className={`w-full px-4 py-3 border rounded-[14px] focus:outline-none focus:ring-2 focus:ring-[#1F2977] ${
                      formik.touched.verificationEmail && formik.errors.verificationEmail ? "border-red-500" : ""
                    }`}
                  />
                  {formik.touched.verificationEmail && formik.errors.verificationEmail && (
                    <div className="text-red-500 text-start text-sm mt-1">{formik.errors.verificationEmail}</div>
                  )}
                </div>
                {/* <div className="text-right mb-5">
                  <Link
                    to="#"
                    className="text-[#0061FF] text-xs hover:underline"
                  >
                    Forgot Password?
                  </Link>
                </div> */}
                <button
                  type="submit"
                  className="w-full py-3 rounded-[27px] bg-primary text-white text-lg font-semibold mt-5 hover:bg-[#131a5e]"
                >
                  Login
                </button>
              </form>
              {/* Sign Up Link */}
              <p className="mt-4 text-gray-500 text-sm">
                Don’t have an account?{" "}
                <Link to="/signup" className="text-[#1F2977] font-medium hover:underline">
                  Sign up
                </Link>
              </p>
            </div>
          )}
        </div>

        {/* Verification Code Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
              {isOtpLogin ? (
                <>
                  <div className=" flex justify-center">
                    <p className="text-gray-500 mb-3">
                      Time left:{" "}
                      <span className="font-bold text-red-500">
                        {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, "0")}
                      </span>
                    </p>
                  </div>
                  <h3 className="text-xl font-bold text-center text-gray-800">Enter Verification Code</h3>
                  <form onSubmit={handleVerify} className="space-y-4">
                    <div>
                      <input
                        type="text"
                        id="verificationCode"
                        className="w-full px-4 py-2 mt-1 border rounded-lg shadow-sm focus:ring-green-500 focus:border-green-500"
                        placeholder="Enter the code sent to your email"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700"
                    >
                      Verify
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsModalOpen(false)}
                      className="w-full px-4 py-2 mt-2 text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300"
                    >
                      Cancel
                    </button>
                  </form>
                </>
              ) : (
                <div onSubmit={handleSubmit} className="flex items-center justify-center ">
                  <div className="w-full pt-10 rounded-lg relative text-center">
                    <h1 className="text-5xl font-bold text-primary mb-5">Hi there!</h1>
                    <p className="text-[#1B1B1B] text-2xl mb-9">
                      Welcome to <span className="font-bold text-primary">ACT</span>
                    </p>

                    <form onSubmit={handleSubmit}>
                      <div className="mb-9">
                        <input
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="email"
                          placeholder="Email"
                          className={`w-full px-4 py-3 border rounded-[14px] ${
                            errors.email && touched.email ? "border-red-500" : "border-[#B1B1B1]"
                          } focus:outline-none focus:ring-2 focus:ring-[#1F2977]`}
                        />
                        {errors.email && touched.email && (
                          <p className="text-red-500 text-start text-sm mt-1">email is {errors.email}</p>
                        )}
                      </div>
                      {/* Password Input */}
                      <div className="mb-2">
                        <input
                          id="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          className={`w-full px-4 py-3 border rounded-[14px] ${
                            errors.password && touched.password ? "border-red-500" : "border-[#B1B1B1]"
                          } focus:outline-none focus:ring-2 focus:ring-[#1F2977]`}
                        />
                        {errors.password && touched.password && (
                          <p className="text-red-500 text-start text-sm mt-1">password is {errors.password}</p>
                        )}
                      </div>
                      {/* Login Button */}
                      <button
                        type="submit"
                        className="w-full py-3 mt-6 mb-2 rounded-[27px] bg-primary text-white text-lg font-semibold hover:bg-[#131a5e]"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </div>
              )}
              {isOtpLogin ? (
                <p
                  onClick={() => {
                    setIsOtpLogin(false);
                    setOtpSent(false);
                  }}
                  className="text-sm text-blue-500 cursor-pointer"
                >
                  Login with password
                </p>
              ) : (
                <p onClick={() => setIsOtpLogin(true)} className="text-sm text-blue-500 cursor-pointer">
                  Login with OTP
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </GoogleOAuthProvider>
  );
}

export default LoginPage;

import React, { useCallback, useMemo, useState } from "react";
import Loader from "../components/shared/Loader";
import { useContactWithSupportMutation } from "../store/apis/supportAPI";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useToastSpinner from "../hooks/useToastSpinner";
import { useFormik } from "formik";
import { supportMessageSchema } from "../Validations/contactFormValidations";

const SendRequest = () => {
  const [contactWithSupport] = useContactWithSupportMutation();

  // const { toastSpinner } = useToastSpinner();

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);

  const { toastSpinner } = useToastSpinner();

  //handlers
  const onSubmit = async ({ name, email, subject, message }, actions) => {
    const data = {
      name,
      email,
      subject,
      message,
    };
    await contactWithSupport(data);
    // const res = await contactWithSupport(data);
    // if (res.error) {
    //   toast.error("Faild to send: " + res.error.data.message);
    // }
    actions.resetForm();
    toast.success(
      "We received your email. We will get in touch with you soon."
    );
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: client?.FullName,
      email: client?.Email,
      subject: "",
      message: "",
    },
    validationSchema: supportMessageSchema,
    onSubmit,
  });

  // if (isSubmitting) {
  //   return <Loader />;
  // }

  return (
    <section>
      <h2 className="text-5xl font-bold text-white my-10">
        Send a Message to ACT office
      </h2>
      <form
        className="bg-white py-7 px-5 text-[#252525] mt-6"
        onSubmit={handleSubmit}
      >
        <div className="w-8/12">
          {/* Pay out method */}
          <div>
            <label htmlFor="payOutMethod" className="block font-medium mb-3">
              Subject
            </label>
            <input
              className="w-full rounded border border-borderColor3 p-3 focus:ring-blue-500 focus:border-blue-500"
              id="subject"
              // onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter the subject of your message"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.subject}
              name="subject"
            />
          </div>

          {/* Notes */}
          <div className="mb-6">
            <label htmlFor="notes" className="block font-medium mb-3">
              Message
            </label>
            <textarea
              onBlur={handleBlur}
              onChange={handleChange}
              id="message"
              value={values.message}
              name="message"
              rows="4"
              className="w-full border border-borderColor3 rounded p-3 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter your message here..."
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-[200px] h-[50px] text-xl bg-primary text-white font-bold py-3 rounded hover:bg-blue-800 transition"
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
};

export default SendRequest;

import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddCardIcon from "@mui/icons-material/AddCard";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { useAddClientMutation, useFetchCoilelClientsQuery, useUpdateClientMutation } from "../store";
import FilterableTable from "../components/features/Dashboard/FilterableTable";
import MainDialog from "../components/Dialog/MainDialog";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../components/features/Dashboard/css/pdf.css";
import { useUploadDocMutation } from "../store/apis/documentApi";
import Loader from "../components/shared/Loader";
import useToastSpinner from "../hooks/useToastSpinner";
import RecipientModal from "../components/Dialog/RecipientModal";
import { useFormik } from "formik";
import { recipientSchema } from "../Validations/recipientFormValidation";

export default function Recipients() {
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    name: "",
    forID: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState("");
  const [tableData, setTableData] = useState([]);
  const [file, setFile] = useState(null);
  const [rowData, setRowData] = useState({});

  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const { toastSpinner } = useToastSpinner();
  const navigate = useNavigate();

  const { ID, MobileAbroad, DefaultPayment, SecendaryEmail } = client || {};
  const payload = useMemo(() => ({ clientID: ID, coilelID: MobileAbroad, token }), [ID, MobileAbroad, token]);
  const { data = [], error, isLoading, isFetching, refetch } = useFetchCoilelClientsQuery(payload);
  const [updateClient, { isLoading: isUpdatingUser }] = useUpdateClientMutation();
  const [uploadDoc, { isLoading: fileLoading }] = useUploadDocMutation();

  const [addClient] = useAddClientMutation();

  const onSubmit = async (values, actions) => {
    const payload = {
      clientID: ID,
      coilelID: MobileAbroad,
      fullName: values?.fullName,
      userID: values?.userID,
      token,
    };
    try {
      const res = await toastSpinner(addClient(payload), "Submitting please wait...");

      // if (res?.error) {
      //   // Handle additional error details if needed
      //   toast.error("Failed to login: " + res.error.data.message);
      // }
    } catch (error) {
      console.error("Error during login:", error);

      // Optionally handle any unexpected errors
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      // Ensure the form is reset regardless of success or failure
      actions.resetForm();
      setIsOpen(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      fullName: "",
      userID: "",
      clientID: ID,
    },
    validationSchema: recipientSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (token) refetch();
  }, [token, refetch]);
  useEffect(() => {
    if (data?.data) setTableData(data.data);
  }, [data, rowData?.original?.ID, rowData?.id]);

  useEffect(() => {
    setDefaultPayment(DefaultPayment);
  }, [DefaultPayment]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "ID",
        header: "ID",
        size: 80,
        filterFn: "equals",
      },
      {
        accessorKey: "FullName",
        header: "Name",
        size: 80,
        filterFn: "contains",
      },
      {
        accessorKey: "DefaultPayment",
        header: "Default Payment method",
        filterFn: "equals",
        filterSelectOptions: ["Bank", "P.P. Card"],
        filterVariant: "select",
      },
      {
        id: "CardStatus",
        accessorFn: (r) => (r.CardStatus === "Need To order" ? "Processing" : r.CardStatus),
        header: "P.P. Card Status",
        filterFn: "equals",
        filterSelectOptions: ["Processing", "Ordered", "Active", "N/A"],
        filterVariant: "select",
      },
    ],
    []
  );

  const handleModal = () => {
    setIsOpen(true);
  };
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handlePaymentMethodChange = useCallback((e) => {
    setDefaultPayment(e.target.value);
  }, []);

  const submitPaymentMethod = useCallback(async () => {
    setDialogState({ ...dialogState, isOpen: false });

    try {
      await toastSpinner(
        updateClient({
          clientID: ID,
          forID: dialogState.forID,
          field: "DefaultPayment",
          value: defaultPayment,
          token,
        }),
        "Updating payment method...", // Loading message
        "Payment method updated successfully.", // Success message
        "Failed to update the payment method." // Error message
      );

      refetch(); // Refetch data after successful update
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  }, [dialogState, toastSpinner, updateClient, ID, defaultPayment, token, refetch]);

  const handleRequestCard = useCallback((row) => {
    if (row?.original?.CardStatus === "N/A") {
      setDialogState({
        isOpen: true,
        name: "requestCard",
        forID: row?.original?.ID,
      });
    } else {
      toast.error("You have already a card on file!");
    }
  }, []);

  const submitRequestCard = useCallback(async () => {
    try {
      await toastSpinner(
        updateClient({
          clientID: ID,
          forID: dialogState.forID,
          field: "CardStatus",
          value: "Ordered",
          token,
        }),
        "Submitting card request...", // Loading message
        "Card request submitted successfully.", // Success message
        "Failed to submit card request." // Error message
      );

      navigate("/account"); // Redirect after success
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  }, [toastSpinner, updateClient, ID, dialogState.forID, token, navigate]);

  const handleRemove = useCallback((row) => {
    setDialogState({
      isOpen: true,
      name: "deleteCoilel",
      forID: row?.original?.ID,
    });
  }, []);

  const submitDeleteCoilel = useCallback(async () => {
    setDialogState({ ...dialogState, isOpen: false });

    try {
      await toastSpinner(
        updateClient({
          clientID: ID,
          forID: dialogState.forID,
          field: "Group",
          value: null,
          token,
        }),
        "Deleting group...",
        "Group deleted successfully.",
        "Failed to delete the group."
      );

      refetch(); // Refetch data after successful deletion
    } catch (error) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    }
  }, [dialogState, toastSpinner, updateClient, ID, token, refetch]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("permission", "Office only");
      // formData.append("customerID", rowData?.original?.ID);
      formData.append("customerID", client.ID);
      formData.append("customerAccountID", rowData?.id);

      try {
        await uploadDoc({ fileData: formData, token, clientID: ID });
        toast.success("File uploaded successfully.");
        setFile(null);
      } catch (err) {
        toast.error("There was an server side error!");
      }
    } else {
      toast.error("No file selected for upload!");
    }
  };

  const handleExportRows = useCallback((rows) => {
    const rowData = rows.map((row) => {
      const { ID, FullName, DefaultPayment, CardStatus } = row.original;
      return {
        ID,
        FullName,
        DefaultPayment,
        CardStatus: CardStatus === "Need To order" ? "Processing" : CardStatus,
      };
    });
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  }, []);

  const csvConfig = useMemo(
    () =>
      mkConfig({
        fieldSeparator: ",",
        useKeysAsHeaders: true,
        filename: "ACTClients",
      }),
    []
  );

  const dialogSubmitHandler = useMemo(() => {
    switch (dialogState.name) {
      case "changePaymentMethod":
        return submitPaymentMethod;
      case "requestCard":
        return submitRequestCard;
      case "deleteCoilel":
        return submitDeleteCoilel;
      default:
        return null;
    }
  }, [dialogState.name, submitPaymentMethod, submitRequestCard, submitDeleteCoilel]);

  const dialogContent = useMemo(() => {
    switch (dialogState.name) {
      case "changePaymentMethod":
        return (
          <div className="user-detail">
            <div>
              <span className="user-label">Default Payment method:</span>
              <select className="user-value" value={defaultPayment} onChange={handlePaymentMethodChange}>
                <option value="" disabled>
                  Please Select
                </option>
                <option value="Bank">Bank Pay out</option>
                <option value="P.P. Card">Prepaid Card Top-Up</option>
              </select>
            </div>
          </div>
        );
      case "requestCard":
        return (
          <div style={{ width: "100%" }}>
            <h1>Are you sure to request card?</h1>
          </div>
        );
      case "deleteCoilel":
        return (
          <div style={{ width: "100%" }}>
            <h1>Are you sure to remove from your Kollel?</h1>
          </div>
        );
      default:
        return null;
    }
  }, [dialogState.name, defaultPayment, handlePaymentMethodChange]);

  const options = {
    muiToolbarAlertBannerProps: error
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        {/* 
        // client wants to remove this feature

        <Tooltip title="Change Default payment method">
          <IconButton onClick={() => handleDefaultPayment(row)}>
            <PriceChangeIcon />
          </IconButton>
        </Tooltip>
         */}
        <Tooltip title="Request a P.P. Card">
          <IconButton disabled={row.CardStatus === "Ordered"} onClick={() => handleRequestCard(row)}>
            <AddCardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove from your Kollel">
          <IconButton color="error" onClick={() => handleRemove(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload Doc">
          <IconButton color="error" onClick={() => setRowData(row)}>
            <input
              accept=".csv,application/pdf, image/jpeg"
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <CloudUploadIcon className="upload-button" />
            </label>
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Download csv
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleModal}
          startIcon={<AddIcon />}
        >
          Add a Kollel member
        </Button>
      </Box>
    ),
  };

  const state = useMemo(
    () => ({
      isLoading,
      isSaving: isUpdatingUser,
      showAlertBanner: error,
      showProgressBars: isFetching,
    }),
    [isLoading, isUpdatingUser, error, isFetching]
  );

  // Loader overlay rendering approach
  const renderLoaderOverlay = () => {
    if (fileLoading) {
      return (
        <div className="loader-overlay">
          <Loader />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {renderLoaderOverlay()} {/* Loader overlay */}
      <div className="tableContainer extraPadding" style={{ width: "100%" }}>
        <FilterableTable data={tableData || data} columns={columns} options={options} state={state} />
        <MainDialog
          cardBody={dialogContent}
          isDialogOpen={dialogState.isOpen}
          setIsDialogOpen={(isOpen) => setDialogState({ ...dialogState, isOpen })}
          submitHandler={dialogSubmitHandler}
        />
        {isOpen && (
          <RecipientModal
            handleModalClose={handleModalClose}
            setIsOpen={setIsOpen}
            values={values}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        )}
      </div>
    </>
  );
}

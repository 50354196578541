/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import pdfBanner from "../../assets/pdfBanner.png";
import receiptImage from "../../assets/receiptBg.jpg";
import formatDate from "../../utils/formateDate";
import calculateTransactionType from "../../utils/calculateTransactionType";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSendInvoiceMailMutation } from "../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useToastSpinner from "../../hooks/useToastSpinner";

const ReceiptPreview = ({ isOpen, onClose, tableData }) => {
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const token = useSelector((state) => state.user.token);
  const [sendInvoiceMail] = useSendInvoiceMailMutation();
  const { toastSpinner } = useToastSpinner();
  const {
    Amount,
    DDate,
    Note,
    PaidIn_PaidOut,
    Total,
    OnHold,
    SentToA_FullName,
    SentToC_FullName,
    IDTransactions,
  } = tableData || {};

  const downloadPDF = async (row) => {
    const invoiceType = "Receipt";
    setTimeout(async () => {
      const doc = new jsPDF("p", "pt", "a4");
      const input = document.getElementById("pdfInvoice");
      await html2canvas(input, { backgroundColor: "#ffffff" }).then(
        (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, pdfWidth, pdfHeight, "F");

          doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          doc.save(
            `${invoiceType} ${formatDate(DDate)}, ACT#${client?.ID}.pdf`
          );
        }
      );
    }, 100);
  };

  const printReceipt = () => {
    const printContent = document.getElementById("pdfInvoicePrint");
    if (!printContent) return;

    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
    <html>
      <head>
        <title>Print Receipt</title>
        <style>
          @media print {
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding:0;
              display:flex;
              justify-content: center;
            }
            #receipt-content {
            
              background-color: "#233273";
               max-width: 1000px; 
              text-align: center;        
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        </style>
      </head>
      <body>
        <div id="receipt-content">
          ${printContent.innerHTML}
        </div>
      </body>
    </html>
  `);
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  const generateAndSendPDF = (tableData) => {
    // Invoice type
    const invoiceType =
      tableData?.PaidIn_PaidOut < 0 ? "Remittance" : "Receipt";
    setTimeout(() => {
      const doc = new jsPDF("p", "pt", "a4");
      const input = document.getElementById("pdfInvoice");

      html2canvas(input, { backgroundColor: "#ffffff" }).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdfWidth = doc.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          doc.setFillColor(255, 255, 255);
          doc.rect(0, 0, pdfWidth, pdfHeight, "F");

          doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

          // Convert PDF to blob
          const pdfBlob = doc.output("blob");

          // Create FormData object and append the blob
          const formData = new FormData();
          // Receipt Jan 03 2025, ACT#5543
          formData.append(
            "pdf",
            pdfBlob,
            `${invoiceType} ${formatDate(tableData.DDate)}, ACT#${
              client?.ID
            }.pdf`
          );
          if (!tableData) {
            toast.error(
              "Invalid parameters. Ensure all required data is provided."
            );
            return;
          }
          try {
            await toastSpinner(
              sendInvoiceMail({
                token,
                clientID: client?.ID,
                IDTransactions: tableData.IDTransactions,
                formData,
                invoiceType,
              }),
              "Sending receipt...",
              "Email has been sent successfully.",
              "There was a server-side error while sending the receipt!"
            );

            // toast.success("Email has been sent successfully.");
          } catch (error) {
            toast.error("There was an server side error!");
          }
        }
      );
    }, 100);
  };

  const formattedTransactionDate = formatDate(DDate);
  const transactionType = calculateTransactionType(PaidIn_PaidOut, OnHold);
  const invoiceType = PaidIn_PaidOut < 0 ? "Remittance" : "Receipt";
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-4xl bg-white rounded-xl shadow-xl p-6">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 p-2 rounded-full "
          onClick={onClose}
        >
          ✕
        </button>

        <div id="pdfInvoice">
          <img src={pdfBanner} alt="Letterhead" className="w-full mb-4" />

          <div className="z-50 flex max-w-5xl items-center justify-center bg-black bg-opacity-50">
            <div className="relative w-full max-w-5xl bg-white p-6 pb-0">
              <div className="relative">
                <img
                  src={receiptImage}
                  alt="Receipt"
                  className="w-full mx-auto mb-4 rounded-lg"
                />
                <h1 className="absolute top-28 left-1/2 transform -translate-x-1/2 text-2xl font-bold text-center">
                  Donation Receipt
                </h1>
                <p className="absolute bottom-28 left-14 text-2xl">
                  <span>Amount:</span> £ {Amount}
                </p>
                <p className="absolute bottom-28 bg-[#F6F6F6] right-14 text-2xl script-font">
                  <span>Signature A Adler</span>
                </p>
                <p className="absolute top-1/2 left-1/2 text-2xl transform -translate-x-1/2">
                  <span>Name:</span> {SentToA_FullName || SentToC_FullName}
                </p>
                <p className="absolute bottom-60 left-14 text-2xl">
                  <span>Date:</span> {formattedTransactionDate}
                </p>
                <p className="absolute bg-transparent bottom-60 right-14 text-2xl">
                  <span>Receipt No. </span> {IDTransactions - 10000}
                </p>
              </div>
            </div>
          </div>

          <div className="h-10">
            <p className="text-center text-2xl font-semibold ">Thank you</p>
          </div>
        </div>

        <div
          id="pdfInvoicePrint"
          style={{
            position: "absolute",
            left: "-15000px",
            top: "-15000px",
            width: "100%",
          }}
        >
          {/* Banner */}
          <img
            src={pdfBanner}
            alt="Letterhead"
            style={{ width: "100%", marginBottom: "1rem" }}
          />

          <div
            style={{
              zIndex: 50,
              display: "flex",
              // maxWidth: "80rem",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                // maxWidth: "80rem",
                backgroundColor: "white",
                padding: "1.5rem",
                paddingBottom: 0,
              }}
            >
              <div style={{ position: "relative" }}>
                <img
                  src={receiptImage}
                  alt="Receipt"
                  style={{
                    width: "100%",
                    margin: "0 auto 1rem",
                    borderRadius: "0.5rem",
                  }}
                />
                <h1
                  style={{
                    position: "absolute",
                    top: "7rem",
                    left: "50%",
                    transform: "translateX(-50%)",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Donation Receipt
                </h1>
                <p
                  style={{
                    position: "absolute",
                    bottom: "5rem",
                    left: "3.5rem",
                    fontSize: "1.5rem",
                  }}
                >
                  <span>Amount:</span> £ {Amount}
                </p>
                <p
                  style={{
                    position: "absolute",
                    bottom: "5rem",
                    right: "3.5rem",
                    fontSize: "1.5rem",
                    backgroundColor: "#F6F6F6",
                    fontFamily: "cursive",
                  }}
                >
                  <span>Signature A Adler</span>
                </p>
                <p
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    fontSize: "1.5rem",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <span>Name:</span> {SentToA_FullName || SentToC_FullName}
                </p>
                <p
                  style={{
                    position: "absolute",
                    bottom: "12rem",
                    left: "3.5rem",
                    fontSize: "1.5rem",
                  }}
                >
                  <span>Date:</span> {formattedTransactionDate}
                </p>
                <p
                  style={{
                    position: "absolute",
                    bottom: "12rem",
                    right: "3.5rem",
                    fontSize: "1.5rem",
                  }}
                >
                  <span>Receipt No. </span> {IDTransactions - 10000}
                </p>
              </div>
            </div>
          </div>
          {/* Thank You Message */}
          <div style={{ height: "2.5rem" }}>
            <p
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                fontWeight: "600",
              }}
            >
              Thank you
            </p>
          </div>
        </div>

        <div className="flex justify-center gap-4 mt-6">
          <button
            onClick={printReceipt}
            className="flex items-center gap-2 px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 9v6M18 9v6M6 15l6 6m0 0l6-6m-6 6V3"
              ></path>
            </svg>
            Print
          </button>
          <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700"
            onClick={downloadPDF}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v12m0 0l-3-3m3 3l3-3M6 12H3m15 0h3m-3-6V3m0 18v-3m-6 6H3a1 1 0 01-1-1V4a1 1 0 011-1h18a1 1 0 011 1v14a1 1 0 01-1 1h-6z"
              ></path>
            </svg>
            Download
          </button>
          <button
            className="flex items-center gap-2 px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
            onClick={() => generateAndSendPDF(tableData)}
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 12H8m4-4v8m-9 4h18M3 8h18"
              ></path>
            </svg>
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptPreview;

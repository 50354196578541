import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useFetchUsersClientsQuery,
  setUsersClients,
  useFetchClientsTransactionsQuery,
  useRoshCoilelTopupMutation,
  useFetchCoilelClientsQuery,
} from "../store";
import ClientsDetails from "../components/features/Dashboard/ClientsDetails";
import TransactionsTable from "../components/features/Dashboard/TransactionsTable";
import Loader from "../components/shared/Loader";
import Users from "../components/Users";
import CustomTable from "../components/shared/customTable/CustomTable";
import { toast } from "react-toastify";

function DashboardPage() {
  const [tableData, setTableData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const clients = useSelector((state) => state.user.clients);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const client = useSelector((state) => state.user.clients[selectedClient]);
  const { ID, MobileAbroad } = client || {};
  const payload = { clientID: ID, token };

  const {
    data: clientTransactionData,
    error: clientTransactionError,
    isLoading: clientTransactionLoading,
    isFetching,
    refetch: clentTransactionRefetch,
  } = useFetchClientsTransactionsQuery(payload, {
    skip: !client?.ID,
  });

  const { isError, isSuccess, data, error, isLoading, refetch } =
    useFetchUsersClientsQuery(token, {
      skip: !token,
    });

  const [requestTopup, { isLoading: topupLoading }] =
    useRoshCoilelTopupMutation();

  // Initialize transactionData with clientTransactionData whenever clientTransactionData changes
  useEffect(() => {
    //add from-to name to the note
    if (!clientTransactionData?.data) return;
    // if (clientTransactionData?.data) {
    setTransactionData(
      clientTransactionData.data.map((transaction) => {
        const FromName =
          transaction.PaidIn_PaidOut > 0
            ? transaction.SentToA_FullName || transaction.SentToC_FullName
            : null;
        const ToName =
          transaction.PaidIn_PaidOut < 0
            ? transaction.SentToA_FullName || transaction.SentToC_FullName
            : null;
        const Note = transaction.Note;
        if (!FromName && !ToName) return transaction;
        if (FromName || ToName)
          return {
            ...transaction,
            Note: `${FromName ? `From: ${FromName}` : ""} ${
              ToName ? `To: ${ToName}` : ""
            }${Note ? `, Note: ${Note}` : ""}`,
          };
      })
    );
    // }
  }, [clientTransactionData]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (isSuccess && data?.data) {
      dispatch(setUsersClients(data.data));
    }
  }, [dispatch, isSuccess, data]);

  // Refetch clients data whenever the component is mounted
  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch, isFetching]);

  const columnsForRecipents = [
    { label: "Date", key: "Date" },
    { label: "Transaction type", key: "TransactionType" },
    { label: "Amount", key: "Amount" },
    { label: "Money type", key: "MoneyType" },
    { label: "Fee", key: "Fee" },
    { label: "Total", key: "Total" },
    { label: "Note", key: "Note" },
  ];

  const columnsForPdf = [
    { label: "Date", key: "Date" },
    { label: "Transaction type", key: "TransactionType" },
    { label: "Note", key: "Note" },
    { label: "Amount", key: "Amount" },
    { label: "Money type", key: "MoneyType" },
    { label: "Fee", key: "Fee" },
    { label: "Total", key: "Total" },
  ];

  // Handle input change for the amount and paymentMethod columns
  const handleInputChange = (e, rowId, key) => {
    const { value } = e.target;
    setTableData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.ID === rowId ? { ...row, [key]: value } : row
      );
      return updatedData;
    });
  };

  // Function to prepare the structured data and make the API call
  const handleSubmit = async () => {
    const coilel = tableData
      .map((client) => ({
        clientID: Number(client.ID),
        amount: Number(client.amount) || 0,
        PaymentMethod: client.paymentMethod || "Bank",
      }))
      .filter((client) => client.amount !== 0); // Filter out entries with amount = 0

    const payload = {
      clientID: Number(ID),
      coilel,
      token,
    };

    // Call API
    try {
      const res = await requestTopup(payload);
      if (!res.error) {
        toast.success(res?.data?.message);
        // Reset the amount and paymentMethod fields
        const resetData = tableData.map((client) => ({
          ...client,
          paymentMethod: "",
          amount: "",
        }));

        setTableData(resetData);
      } else {
        toast.error("There is a server-side error! ");
      }
    } catch (err) {
      toast.error("There is a server-side error!");
    }
  };

  // Function to handle key press (Enter) and trigger API call
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  if (isLoading || clientTransactionLoading || !clients.length) {
    return <Loader />;
  } else {
    return (
      <div className="min-h-screen">
        <ClientsDetails />
        <CustomTable
          isLoading={isLoading}
          clientTransactionLoading={clientTransactionLoading}
          columns={columnsForRecipents}
          columnsPdf={columnsForPdf}
          data={transactionData}
          // getTopUpOptions={getTopUpOptions}
          handleInputChange={handleInputChange}
          handleKeyPress={handleKeyPress}
          handleSubmit={handleSubmit}
          actions
        />
        {/* <Users /> */}
      </div>
    );
  }
}

export default DashboardPage;
